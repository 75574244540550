import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../auth";
import styles from "./Applications.module.css";
import { getUserApps, getTable } from "../../services/ReadyOneAPI";
import { Title, Footer, Video } from "./Layout";
import { AppDrawer, AllApps, MyApps } from "./Apps";
import { useNavigate, useLocation } from "react-router-dom";

const Applications = () => {
  const [myApps, setMyApps] = useState([]);
  const [applications, setApplications] = useState([]);
  const [showMyApps, setShowMyApps] = useState(false);
  const [showAllApps, setShowAllApps] = useState(false);
  const navigate = useNavigate();

  const { username, email, setAuthenticated, restricted } = useContext(AccountContext);
  const search = useLocation().hash;
  const accessToken = new URLSearchParams(search).get("#access_token") || "";

  useEffect(() => {
    if (accessToken) navigate("/Applications");
    getTable("AppStreams").then((result) => {
      result.body.Items.push({
        "appName": "Dashboards",
        "appRoute": "/Dashboards",
        "category": "addon",
        "image": "https://s3-us-gov-west-1.amazonaws.com/resources.readyone.net/site_images/app_streams/dashboards.png",
        "mode": "elm",
        "title": "Dashboards"
       })
      setApplications(result.body.Items.sort(compare).filter(hidden));
    });
    getUserApps(`saic_${username}`).then((result) => {
      if (result?.app_list) {
        setMyApps(result.app_list);
      }
    });
  }, []);

  const compare = (a, b) => {
    return b.category === "core" ? 1 : -1;
  };

  const hidden = (app) => {
    if(app.restricted  && restricted) return true; 
    if(app.users && app.users.includes(email)) return true;
    return !app.hide;
  };

  return (
    <>
      <div
        className={`${styles.gettingStartedWholeBox} ${
          (showMyApps || showAllApps) && styles.hideBox
        }`}
      >
        <Title
          title="Applications"
          subtitle="Select an application to get started"
        />

        <AppDrawer
          toggleAllApps={() => setShowAllApps(!showAllApps)}
          toggleMyApps={() => setShowMyApps(!showMyApps)}
        />

        <Footer
          title="Getting started"
          body="In this video, you will find some useful information on getting
                started with App Streams. This video demonstrates launching
                engineering applications within your web browser, and navigating
                the App Streams toolbar. If you need additional help, click the
                question mark on the bottom left to ask any question you may
                have."
        >
          <Video url="https://s3.us-gov-west-1.amazonaws.com/resources.readyone.net/demos/ReadyOne+Demo+v2.m4v" />
        </Footer>
      </div>
      {!restricted && (
        <>
          <MyApps
            applications={applications}
            setAuthenticated={setAuthenticated}
            myApps={myApps}
            setMyApps={setMyApps}
            show={showMyApps}
            stopShow={() => setShowMyApps(!showMyApps)}
          />
          <AllApps
            applications={applications}
            setAuthenticated={setAuthenticated}
            myApps={applications.map((app) => app.appName)}
            setMyApps={setMyApps}
            show={showAllApps}
            stopShow={() => setShowAllApps(!showAllApps)}
          />
        </>
      )}
    </>
  );
};

export default Applications;
